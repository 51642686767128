import { APIOps } from "@/apis/api-config";
import ApiPaths from "@/apis/base-api-paths";

export default class CorporateApi extends ApiPaths {
  async postCoroporateLogin(): Promise<
    APIOps["login_api_corporate_auth_login_post"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.post(this.toCorporateURL("/auth/login"))).data;
  }

  async getCorporateUserList(
    queryParams: APIOps["get_user_table_for_corp_api_corporate_user_list_get"]["parameters"]["query"],
  ): Promise<
    APIOps["get_user_table_for_corp_api_corporate_user_list_get"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.get(this.toCorporateURL("/user/list"), { params: queryParams })).data;
  }

  async getCorporateUserDetail(
    userId: APIOps["get_user_detail_for_corp_api_corporate_user__user_id__detail_get"]["parameters"]["path"]["user_id"],
  ): Promise<
    APIOps["get_user_detail_for_corp_api_corporate_user__user_id__detail_get"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.get(this.toCorporateURL(`/user/${userId}/detail`))).data;
  }

  async getCorporateInstagramList(
    queryParams: APIOps["get_instagram_table_for_corp_api_corporate_instagram_account_list_get"]["parameters"]["query"],
  ): Promise<
    APIOps["get_instagram_table_for_corp_api_corporate_instagram_account_list_get"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.get(this.toCorporateURL("/instagram_account/list"), { params: queryParams })).data;
  }

  async getCorporateInstagramNoQueryList(
    queryParams: APIOps["get_no_query_instagram_table_for_corp_api_corporate_instagram_account_noQuery_list_get"]["parameters"]["query"],
    requestBody: APIOps["get_no_query_instagram_table_for_corp_api_corporate_instagram_account_noQuery_list_get"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIOps["get_no_query_instagram_table_for_corp_api_corporate_instagram_account_noQuery_list_get"]["responses"]["200"]["content"]["application/json"]
  > {
    return (
      await this.get(this.toCorporateURL("/instagram_account/noQuery/list"), {
        params: queryParams,
        data: requestBody,
      })
    ).data;
  }

  async getCorporateInstagramSimpleList(): Promise<
    APIOps["get_instagram_table_simple_for_corp_api_corporate_instagram_account_simple_list_get"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.get(this.toCorporateURL("/instagram_account/simple/list"))).data;
  }

  async getCorporateInstagramInsightList(
    queryParams: APIOps["get_instagram_table_insight_for_corp_api_corporate_instagram_account_insight_list_post"]["parameters"]["query"],
    requestBody: APIOps["get_instagram_table_insight_for_corp_api_corporate_instagram_account_insight_list_post"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIOps["get_instagram_table_insight_for_corp_api_corporate_instagram_account_insight_list_post"]["responses"]["200"]["content"]["application/json"]
  > {
    return (
      await this.post(this.toCorporateURL("/instagram_account/insight/list"), requestBody, {
        params: queryParams,
      })
    ).data;
  }

  async getCorporateInstagramDetail(
    instagramAccountId: APIOps["get_instagram_table_detail_for_corp_api_corporate_instagram_account__instagram_account_id__detail_get"]["parameters"]["path"]["instagram_account_id"],
  ): Promise<
    APIOps["get_instagram_table_detail_for_corp_api_corporate_instagram_account__instagram_account_id__detail_get"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.get(this.toCorporateURL(`/instagram_account/${instagramAccountId}/detail`))).data;
  }

  async postCorporateInstagramUpdateLabel(
    instagramAccountId: APIOps["update_instagram_account_labels_for_corp_api_corporate_instagram_account__instagram_account_id__update_labels_post"]["parameters"]["path"]["instagram_account_id"],
    requestBody: APIOps["update_instagram_account_labels_for_corp_api_corporate_instagram_account__instagram_account_id__update_labels_post"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIOps["update_instagram_account_labels_for_corp_api_corporate_instagram_account__instagram_account_id__update_labels_post"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.post(this.toCorporateURL(`/instagram_account/${instagramAccountId}/update/labels`), requestBody))
      .data;
  }
}
