import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";

import { APIOps } from "@/apis/api-config";
import CorporateApi from "@/apis/connect/corporate-api";
import { CORPORATE_DASHBOARD_PATH, CORPORATE_LOGIN_PATH } from "@/global-state/external-path";
import { firebaseAuth } from "@/global-state/firebase-settings";
import {
  authUserAtom,
  idTokenAtom,
  instagramAccountInfoAtom,
  loadingAtom,
  organizationAtom,
} from "@/global-state/jotai-atoms";
import { LocalForageKeys } from "@/global-state/local-forage-keys";

import { useAtom } from "jotai";
import localforage from "localforage";
import { useSnackbar } from "notistack";

export const useCorporateApi = () => {
  const router = useRouter();
  const [, setLoading] = useAtom(loadingAtom);
  const [instagram, setInstagram] = useAtom(instagramAccountInfoAtom);
  const [, setOrganization] = useAtom(organizationAtom);
  const [idToken, setIdToken] = useAtom(idTokenAtom);
  const [, setAuthUser] = useAtom(authUserAtom);
  const coporateApi = useMemo(() => new CorporateApi(idToken, instagram?.instagram_business_id), [idToken, instagram]);
  const { enqueueSnackbar } = useSnackbar();

  // id, 認証情報リセット => error, logout時に使用
  const resetAuth = useCallback(async () => {
    setAuthUser(null);
    setIdToken(null);
    setLoading(false);
    await router.push(CORPORATE_LOGIN_PATH);
  }, []);

  const corporateAuthSync = useCallback(() => {
    setLoading(true);
    try {
      firebaseAuth.onAuthStateChanged(async (user) => {
        if (user) {
          const accessToken = await user.getIdToken();
          await setIdToken(accessToken);
          try {
            const loginCorpApi = new CorporateApi(accessToken);
            const member = await loginCorpApi.postCoroporateLogin();
            setAuthUser(member);
            setInstagram(member.instagram_accounts[0]);
            setOrganization(member.organization);
            await router.push(CORPORATE_DASHBOARD_PATH);
          } catch (e) {
            enqueueSnackbar(e?.response?.data?.detail || e?.message, {
              variant: "error",
            });
            return setIdToken("");
          }
          enqueueSnackbar("管理者ダッシュボードにログイン中です", {
            variant: "success",
          });
        } else {
          return resetAuth();
        }
      });
    } catch (e) {
      enqueueSnackbar(e?.response?.data?.detail || e?.message, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [coporateApi]);

  const postCoroporateLogin = useCallback(
    async (email: string, password: string) => {
      try {
        firebaseAuth
          .signInWithEmailAndPassword(email, password)
          .then(async (cred) => {
            setLoading(true);
            await localforage.setItem(LocalForageKeys.SigninForm.email, email);
            await localforage.setItem(LocalForageKeys.SigninForm.password, password);
            const linkUser = cred.user;
            const accessToken = (await linkUser.getIdToken()) || null;
            await setIdToken(accessToken);
            try {
              const loginCorpApi = new CorporateApi(accessToken);
              const member = await loginCorpApi.postCoroporateLogin();
              setAuthUser(member);
              setInstagram(member.instagram_accounts[0]);
              setOrganization(member.organization);
              await router.push(CORPORATE_DASHBOARD_PATH);
            } catch (e) {
              enqueueSnackbar(e?.response?.data?.detail || e?.message, {
                variant: "error",
              });
              return setIdToken("");
            }
            enqueueSnackbar(`${email + "で" || ""}管理者ダッシュボードにログイン中です`, {
              variant: "success",
            });
          })
          .catch((error) => {
            const code = error.code;
            if (code === "auth/user-not-found") {
              enqueueSnackbar("未登録のユーザーです。新規登録をお願いします。", {
                variant: "error",
              });
            } else if (code === "auth/wrong-password") {
              enqueueSnackbar("パスワードが一致しませんでした。", {
                variant: "error",
              });
            } else {
              enqueueSnackbar(error?.response?.data?.detail || error?.message, {
                variant: "error",
              });
            }
            return setIdToken("");
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (e) {
        enqueueSnackbar(e?.response?.data?.detail || e?.message, {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [coporateApi],
  );

  const corporateLogout = useCallback(async () => {
    setLoading(true);
    try {
      firebaseAuth
        .signOut()
        .then(async () => {
          enqueueSnackbar("ログアウトしました。", {
            variant: "info",
          });
          await router.push(CORPORATE_LOGIN_PATH);
        })
        .catch((error) => {
          enqueueSnackbar(error?.message || "error", {
            variant: "error",
          });
        });
    } catch (e) {
      console.log(e, e?.response?.data?.detail);
      return resetAuth();
    } finally {
      await resetAuth();
      setLoading(false);
    }
  }, [coporateApi]);

  const getCorporateUserList = useCallback(
    async (queryParams: APIOps["get_user_table_for_corp_api_corporate_user_list_get"]["parameters"]["query"]) => {
      setLoading(true);
      try {
        return await coporateApi.getCorporateUserList(queryParams);
      } catch (e) {
        enqueueSnackbar(e?.response?.data?.detail || e?.message, {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [coporateApi],
  );

  const getCorporateUserDetail = useCallback(
    async (userId: string) => {
      setLoading(true);
      try {
        return await coporateApi.getCorporateUserDetail(userId);
      } catch (e) {
        enqueueSnackbar(e?.response?.data?.detail || e?.message, {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [coporateApi],
  );

  const getCorporateInstagramList = useCallback(
    async (
      queryParams: APIOps["get_instagram_table_for_corp_api_corporate_instagram_account_list_get"]["parameters"]["query"],
    ) => {
      setLoading(true);
      try {
        return await coporateApi.getCorporateInstagramList(queryParams);
      } catch (e) {
        enqueueSnackbar(e?.response?.data?.detail || e?.message, {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [coporateApi],
  );

  const getCorporateInstagramNoQueryList = useCallback(
    async (
      queryParams: APIOps["get_no_query_instagram_table_for_corp_api_corporate_instagram_account_noQuery_list_get"]["parameters"]["query"],
      requestBody: APIOps["get_no_query_instagram_table_for_corp_api_corporate_instagram_account_noQuery_list_get"]["requestBody"]["content"]["application/json"],
    ) => {
      try {
        return await coporateApi.getCorporateInstagramNoQueryList(queryParams, requestBody);
      } catch (e) {
        enqueueSnackbar(e?.response?.data?.detail || e?.message, {
          variant: "error",
        });
      }
    },
    [coporateApi],
  );

  const getCorporateInstagramSimpleList = useCallback(async () => {
    try {
      return await coporateApi.getCorporateInstagramSimpleList();
    } catch (e) {
      enqueueSnackbar(e?.response?.data?.detail || e?.message, {
        variant: "error",
      });
    }
  }, [coporateApi]);

  const getCorporateInstagramInsightList = useCallback(
    async (
      queryParams: APIOps["get_instagram_table_insight_for_corp_api_corporate_instagram_account_insight_list_post"]["parameters"]["query"],
      requestBody: APIOps["get_instagram_table_insight_for_corp_api_corporate_instagram_account_insight_list_post"]["requestBody"]["content"]["application/json"],
    ) => {
      try {
        return await coporateApi.getCorporateInstagramInsightList(queryParams, requestBody);
      } catch (e) {
        enqueueSnackbar(e?.response?.data?.detail || e?.message, {
          variant: "error",
        });
      }
    },
    [coporateApi],
  );

  const getCorporateInstagramDetail = useCallback(
    async (instagramAccountId: string) => {
      try {
        return await coporateApi.getCorporateInstagramDetail(instagramAccountId);
      } catch (e) {
        enqueueSnackbar(e?.response?.data?.detail || e?.message, {
          variant: "error",
        });
      }
    },
    [coporateApi],
  );

  const postCorporateInstagramUpdateLabel = useCallback(
    async (instagramAccountId: string, labelIds: Array<string>) => {
      try {
        return await coporateApi.postCorporateInstagramUpdateLabel(instagramAccountId, labelIds);
      } catch (e) {
        enqueueSnackbar(e?.response?.data?.detail || e?.message, {
          variant: "error",
        });
      }
    },
    [coporateApi],
  );

  return {
    corporateAuthSync,
    postCoroporateLogin,
    corporateLogout,
    getCorporateUserList,
    getCorporateUserDetail,
    getCorporateInstagramList,
    getCorporateInstagramNoQueryList,
    getCorporateInstagramSimpleList,
    getCorporateInstagramInsightList,
    getCorporateInstagramDetail,
    postCorporateInstagramUpdateLabel,
  };
};
